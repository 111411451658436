<template>
  <v-dialog v-model="sessionEnd" persistent width="400">
    <v-card elevation="2">
      <v-card-title class="text-h3"
        >Atención <v-spacer />
        <v-icon x-large color="red darken-2">
          mdi-alarm-light
        </v-icon></v-card-title
      >
      <v-card-text>
        Su sesión ha terminado. Será direccionado al inicio.</v-card-text
      >
      <v-card-actions>
        <v-spacer />
        <v-btn color="primary" @click="forceExit()">Entendido</v-btn>
        <v-spacer />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import baseViewMixin from "@/mixins/baseView";
import currentUserMixin from "@/mixins/currentUser";

export default {
  mixins: [baseViewMixin, currentUserMixin],
  data() {
    return {
      sessionEnd: false,
    };
  },
  methods: {
    forceExit() {
      this.internalLogout();
      if (this.$Settings.all.excecutionMode == "FRONT") {
        this.gotoView("logoutfront");
      }

      if (this.$Settings.all.excecutionMode == "WF") {
        this.gotoView("logout");
      }
    },
    validateSession() {
      const fechaEnMiliseg = Date.now();
      const fechaExpires = new Date(this.currentUserInfo.expires);
      if (fechaEnMiliseg >= fechaExpires) {
        this.sessionEnd = true;
      } else {
        setTimeout(() => this.validateSession(), 1000);
      }
    },
  },
  beforeMount() {
    this.validateSession();
  },
};
</script>

<style lang="scss" scoped></style>
