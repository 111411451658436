<template>
  <div>
    <TopMenu />
    <v-container fluid>
      <v-row>
        <v-col cols="12" md="12" :class="classTo">
          <div v-if="page.loading" class="pt-4">
            <v-progress-linear
              indeterminate
              color="primary"
              class="pa-1"
            ></v-progress-linear>
          </div>
          <div v-else>
            <Surf />
            <Graph v-if="$route.name == 'dashboard'" />
            <router-view :key="$route.path"></router-view>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <SessionEnd />
  </div>
</template>

<script>
import baseViewMixin from "@/mixins/baseView";
import currentUserMixin from "@/mixins/currentUser";
import currentDashboardMixin from "@/mixins/currentDashboard";

import TopMenu from "@/components/WFSite/TopMenu";
import Graph from "@/components/WFSite/Graph";
import Surf from "@/components/WFSite/Surf";

import SessionEnd from "@/components/WFSite/SessionEnd";

export default {
  mixins: [baseViewMixin, currentUserMixin, currentDashboardMixin],
  components: { TopMenu, Graph, Surf, SessionEnd },
  methods: {},
  async beforeMount() {
    this.initSignalLoading();
    await this.loadDashboard();
    await this.loadDashboardQuery();
    this.stopSignalLoading();
  },
  computed: {
    classTo() {
      if (!this.$isMobile()) return "pt-0";
      return "pt-2";
    },
  },
};
</script>

<style lang="scss" scoped></style>
