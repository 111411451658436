<template>
  <div>
    <div class="text-center">
      <v-row no-gutters>
        <v-spacer />
        <v-chip
          class="ma-2"
          :color="d.color"
          v-for="d in grilla"
          :key="d.name"
          small
          dark
          v-show="d.value > 0"
          @click="goToUpdate(d.goTo)"
        >
          {{ d.name }} {{ $n(d.value) }}
        </v-chip>
        <v-spacer />
        <Search v-if="true" />
      </v-row>
    </div>
  </div>
</template>

<script>
import baseViewMixin from "@/mixins/baseView";
import currentUserMixin from "@/mixins/currentUser";
import currentDashboardMixin from "@/mixins/currentDashboard";
import Search from "@/components/WFSite/Search";

export default {
  mixins: [baseViewMixin, currentUserMixin, currentDashboardMixin],
  components: {
    Search,
  },
  methods: {
    goToUpdate(to) {
      this.loadDashboard();
      this.gotoView(to);
    },
    cleanExecutive() {
      this.graphDataForDDCExecutive.datasets[0].data = [];
    },
    addDdcExecutive(value) {
      this.graphDataForDDCExecutive.datasets[0].data.push(value);
    },
    cleanAdministrator() {
      this.graphDataForBankAdministrator.datasets[0].data = [];
    },
    addBankAdministrator(value) {
      this.graphDataForBankAdministrator.datasets[0].data.push(value);
    },
    cleanDefender() {
      this.graphDataForDDCDefender.datasets[0].data = [];
    },
    addDDCDefender(value) {
      this.graphDataForDDCDefender.datasets[0].data.push(value);
    },
    cleanBankExecutive() {
      this.graphDataForBankExecutive.datasets[0].data = [];
    },
    addBankExecutive(value) {
      this.graphDataForBankExecutive.datasets[0].data.push(value);
    },
  },
  data() {
    return {
      graphDataForDDCExecutive: {
        datasets: [
          {
            data: [],
            backgroundColor: [
              "#F44336", //Ingresados
              "#E91E63", //Por asignar
              "#9C27B0", //pendiente antecedentes adicionales
              "#3F51B5", //Pendiente cliente
              "#795548", //Pendiente banco
              "#009688", //Pendiente defensor
              "#9E9E9E", //Cerrados
            ],
            label: "Reclamos",
          },
        ],
        labels: [
          "Por revisar",
          "Por asignar",
          "Antecedentes adicionales pendiente",
          "Pendiente cliente",
          "Pendiente banco",
          "Pendiente defensor",
          "Cerrados",
        ],
        goTo: [
          "ddcExecutive-claimsToAdmit",
          "ddcExecutive-claimsPendingAssignment",
          "ddcExecutive-claimsSendPapers",
          "ddcExecutive-claimsPendingClient",
          "ddcExecutive-claimsPendingBank",
          "ddcExecutive-claimsPendingDefender",
          "ddcExecutive-claimsClosed",
        ],
      },
      graphDataForBankAdministrator: {
        datasets: [
          {
            data: [],
            backgroundColor: [
              "#F44336", //Ingresados
              "#E91E63", //Por Responder
              "#9C27B0", //Prorrogables
              "#3F51B5", //pendiente antecedentes adicionales
              "#795548", //Pendiente Cliente
              "#009688", //Pendiente Ejecutivo DDC
              "#607D8B", //Pendiente Defensor
              "#9E9E9E", //Cerrados
            ],
            label: "Reclamos",
          },
        ],
        labels: [
          "Ingresados",
          "Por responder",
          "Prorrogables",
          "Entregar antecedentes adicionales",
          "Pendiente cliente",
          "Pendiente ejecutivo DDC",
          "Pendiente defensor",
          "Cerrados",
        ],
        goTo: [
          "bankAdministrator-claimsCreated",
          "bankAdministrator-claimsForAnswer",
          "bankAdministrator-claimsInExtension",
          "bankAdministrator-claimsSendPapers",
          "bankAdministrator-claimsPendingClient",
          "bankAdministrator-claimsPendingDDC",
          "bankAdministrator-claimsPendingDefender",
          "bankAdministrator-claimsClosed",
        ],
      },
      graphDataForDDCDefender: {
        datasets: [
          {
            data: [],
            backgroundColor: [
              "#F44336", //Pendiente defensor
              "#E91E63", //Incompatibles
              "#9C27B0", //pendiente antecedentes adicionales
              "#3F51B5", //Pendiente Cliente
              "#795548", //Pendiente Banco
              "#9E9E9E", //Cerrados
            ],
            label: "Reclamos",
          },
        ],
        labels: [
          "Pendiente defensor",
          "Incompatibles",
          "Pendiente antecedentes adicionales",
          "Pendiente cliente",
          "Pendiente banco",
          "Cerrados",
        ],
        goTo: [
          "ddcDefender-claimsAssigned",
          "ddcDefender-claimsNotCompatible",
          "ddcDefender-claimsSendPapers",
          "ddcDefender-claimsPendingClient",
          "ddcDefender-claimsPendingBank",
          "ddcDefender-claimsClosed",
        ],
      },
      graphDataForBankExecutive: {
        datasets: [
          {
            data: [],
            backgroundColor: [
              "#F44336", //Ingresados
              "#E91E63", //Por responder
              "#9C27B0", //Prorrogables
              "#3F51B5", //pendiente antecedentes adicionales
              "#795548", //Pendiente cliente
              "#009688", //Pendiente ejecutivo DDC
              "#607D8B", //Pendiente defensor
              "#9E9E9E", //Cerrados
            ],
            label: "Reclamos",
          },
        ],
        labels: [
          "Ingresados",
          "Por responder",
          "Prorrogables",
          "Entregar antecedentes adicionales",
          "Pendiente cliente",
          "Pendiente ejecutivo DDC",
          "Pendiente defensor",
          "Cerrados",
        ],
        goTo: [
          "bankExecutive-claimsCreated",
          "bankExecutive-claimsPendingResponse",
          "bankExecutive-claimsInExtension",
          "bankExecutive-claimsSendPapers",
          "bankExecutive-claimsPendingClient",
          "bankExecutive-claimsPendingDDC",
          "bankExecutive-claimsPendingDefender",
          "bankExecutive-claimsClosed",
        ],
      },
    };
  },
  computed: {
    data() {
      if (
        this.currentUserInfo.roles[0] ==
        this.allConstants.security.userRolesCodes.ddcExecutive
      ) {
        this.cleanExecutive();
        this.addDdcExecutive(this.currentDashboard.ddcExecutive.claimsToAdmit);
        this.addDdcExecutive(
          this.currentDashboard.ddcExecutive.claimsPendingAssignment
        );
        this.addDdcExecutive(
          this.currentDashboard.ddcExecutive.claimsSendPapers
        );
        this.addDdcExecutive(
          this.currentDashboard.ddcExecutive.claimsPendingClient
        );
        this.addDdcExecutive(
          this.currentDashboard.ddcExecutive.claimsPendingBank
        );
        this.addDdcExecutive(
          this.currentDashboard.ddcExecutive.claimsPendingDefender
        );
        this.addDdcExecutive(this.currentDashboard.ddcExecutive.claimsClosed);

        return this.graphDataForDDCExecutive;
      }

      if (
        this.currentUserInfo.roles[0] ==
        this.allConstants.security.userRolesCodes.bankAdministrator
      ) {
        this.cleanAdministrator();
        this.addBankAdministrator(
          this.currentDashboard.bankAdministrator.claimsCreated
        );
        this.addBankAdministrator(
          this.currentDashboard.bankAdministrator.claimsForAnswer
        );
        this.addBankAdministrator(
          this.currentDashboard.bankAdministrator.claimsInExtension
        );
        this.addBankAdministrator(
          this.currentDashboard.bankAdministrator.claimsSendPapers
        );
        this.addBankAdministrator(
          this.currentDashboard.bankAdministrator.claimsPendingClient
        );
        this.addBankAdministrator(
          this.currentDashboard.bankAdministrator.claimsPendingDDC
        );
        this.addBankAdministrator(
          this.currentDashboard.bankAdministrator.claimsPendingDefender
        );
        this.addBankAdministrator(
          this.currentDashboard.bankAdministrator.claimsClosed
        );

        return this.graphDataForBankAdministrator;
      }

      if (
        this.currentUserInfo.roles[0] ==
        this.allConstants.security.userRolesCodes.ddcDefender
      ) {
        this.cleanDefender();
        this.addDDCDefender(this.currentDashboard.ddcDefender.claimsAssigned);
        this.addDDCDefender(
          this.currentDashboard.ddcDefender.claimsNotCompatible
        );
        this.addDDCDefender(this.currentDashboard.ddcDefender.claimsSendPapers);
        this.addDDCDefender(
          this.currentDashboard.ddcDefender.claimsPendingClient
        );
        this.addDDCDefender(
          this.currentDashboard.ddcDefender.claimsPendingBank
        );
        this.addDDCDefender(this.currentDashboard.ddcDefender.claimsClosed);

        return this.graphDataForDDCDefender;
      }

      if (
        this.currentUserInfo.roles[0] ==
        this.allConstants.security.userRolesCodes.bankExecutive
      ) {
        this.cleanBankExecutive();
        this.addBankExecutive(
          this.currentDashboard.bankExecutive.claimsCreated
        );
        this.addBankExecutive(
          this.currentDashboard.bankExecutive.claimsPendingResponse
        );
        this.addBankExecutive(
          this.currentDashboard.bankExecutive.claimsInExtension
        );
        this.addBankExecutive(
          this.currentDashboard.bankExecutive.claimsSendPapers
        );
        this.addBankExecutive(
          this.currentDashboard.bankExecutive.claimsPendingClient
        );
        this.addBankExecutive(
          this.currentDashboard.bankExecutive.claimsPendingDDC
        );
        this.addBankExecutive(
          this.currentDashboard.bankExecutive.claimsPendingDefender
        );
        this.addBankExecutive(this.currentDashboard.bankExecutive.claimsClosed);

        return this.graphDataForBankExecutive;
      }

      return [];
    },
    grilla() {
      let result = [];
      for (let i = 0; i < this.data.labels.length; i++) {
        result.push({
          name: this.data.labels[i],
          value: this.data.datasets[0].data[i],
          color: this.data.datasets[0].backgroundColor[i],
          goTo: this.data.goTo[i],
        });
      }

      return result;
    },
  },
};
</script>

<style lang="scss" scoped></style>
